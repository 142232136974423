const localDataName = "mateInvoice";
const orderStatusOptions = ["Pending", "Shipped", "Delivered"];

const fakedata = {
  key: 1,
  id: "1518713981654",
  number: "#1231",
  orderStatus: "Shipped",
  orderDate: 1518849188360,
  currency: "$",
  type: "SALE",
  customer: "b418fa4f-cd8d-4718-a09c-42ce30b5d840",
  billTo: "REDQ Inc.",
  billToAddress:
    "redq@company.com\n\n405 Mulberry Rd, Mc Grady, \nNC, 28649 \n\nFax: +0(863) 228-7064 \nPhone: +(740) 927-9284",
  billFrom: "Pineapple Inc.",
  billFromAddress:
    "pineapple@company.com\n\n86781 547th Ave, Osmond, \nNE, 68765 \n\nPhone: +(402) 748-3970",
  itemsList: [
    {
      no: 1,
      description: "[119926] 90% 10C Roosevelt (1946-1964)",
      quantity: -100,
      rate: "$ 14",
      amount: "$-525.00",
    },
    {
      no: 2,
      description: "[119926] 90% 10C Roosevelt (1946-1964)",
      quantity: -100,
      rate: "$ 14",
      amount: "$-525.00",
    },
    {
      no: 3,
      description: "[119926] 90% 10C Roosevelt (1946-1964)",
      quantity: -100,
      rate: "$ 5",
      amount: "$-525.00",
    },
  ],
  subTotal: 13300,
  vatRate: 10,
  vatPrice: 1330,
  totalCost: 14630,
};
const newInvoice = {
  orderDate: new Date().getTime(),
  po: "",
  terms: "",
  source: "",
  customer: {
    uuid: "",
    company: "",
    firstName: "",
    lastName: "",
    city: "",
    email: "",
  },

  alternativeEmail: "",
  salesPerson: "",
  location: "",
  gradingService: "",
  gradingInvoiceNumber: "",
  gradingServiceType: "",
  payMethod: "",
  notes: "",
  payMethod: false,
  comment: "",
  internalComment: "",
  trackingInfo: "",
  subTotal: 0,
  currency: "$",
  type: "",

  itemsList: [
    {
      key: 1,
      itemName: "",
      itemType: "unique",
      service: "",
      status: "",
      qty: 0,
      rate: 0,
      amount: 0,
    },
  ],
  subTotal: 0,
  grandTotal: 0,
  egp: 0,
};
const createDemoData = () => {
  return newInvoice;
};

export {
  fakedata,
  createDemoData,
  localDataName,
  newInvoice,
  orderStatusOptions,
};
